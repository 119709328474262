<template>
  <div class="page">
    <!--<div class="form">-->
    <div class="content-block">
      <form-card title="欢迎加入">
        <el-row>
          <el-col>
            <div class="title">恭喜您成功注册移动智能终端生态联盟！</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" :offset="2" :xs="{span:20,offset:2}">
            <div
              class="content"
            >您将优先收到关于联盟动态和标准文档的相关信息，并有机会获得主流应用市场应用「金标认证」优先内测权利。我们开放相关服务时,您将得到通知信息，请您留意。我们的伙伴正在募集中，您可以通过邮箱service@itgsa.com与我们取得联系。</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" :offset="9" :xs="{span:10,offset:6}">
            <div class="button cursor" @click="gobackIndex()">回到首页</div>
          </el-col>
        </el-row>
      </form-card>
    </div>
  </div>
</template>

<script>
import { passWord } from "@/utils/RegExp.js";
import FormCard from "@/components/FormCard.vue";
export default {
  name: "Register",
  components: { FormCard },
  data() {
    return {
      agreeStatus: false,
      imgKeyData: {},
      ruleForm: {
        email: "",
        ckey: "",
        imgCaptcha: "",
        emailCaptcha: "",
        password: "",
        passwordConfirm: "",
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sendCode() {},
    gobackIndex() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-col-17 {
  padding-left: 0px !important;
}
.page {
  background-color: #f8f6f7;
  height: 100%;
  padding: 60px 0px 70px 0px;

  .title {
    font-size: 30px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 48px;
  }
  .content {
    margin: 38px auto 0px auto;
    font-size: 18px;
    font-weight: 400;
    color: #474747d1;
    line-height: 30px;
    text-align: center;
  }
  .button {
    height: 57px;
    line-height: 57px;
    font-size: 18px;
    text-align: center;
    margin-top: 53px;
    background: rgba(255, 255, 255, 1);
    color: #3a3a3a;
    border: 2px solid rgba(58, 58, 58, 1);
    &:hover {
      background: #3a3a3a;
      color: #ffffff;
    }
  }
}
@media screen and (max-width: 767px) {
  .page {
    padding: 0px 0px 70px 0px;
  }
}
</style>